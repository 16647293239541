import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { useEffect, useState } from 'react'
import './App.css'

Sentry.init({
    dsn: 'https://4d3baadc045b4d98a2357293095d04ac@o410110.ingest.sentry.io/6496253',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})

const url =
    'https://script.google.com/macros/s/AKfycbwl4lzWTxEZ2nW2XMzB3Mim2xOPqjbW1IM1wS-jRlwheCuErgDl--GmyzWav-srXsiG/exec'

interface Book {
    id: string
    media: string
    category: string
    title: string
}

const getPreviewURL = (media: string) => {
    let parts = media.split('/')
    return parts.slice(0, -1).join('/') + '/preview'
}

const setVH = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const baseFormURL =
    'https://docs.google.com/forms/d/e/1FAIpQLSejgkFEFU58VCk3xcRnjf2o_pT_Tpb0f6MosbbyFQA_pTK0Yw/viewform?usp=pp_url&entry.1837776684='

function App() {
    const [books, setBooks] = useState<Book[]>([])
    const [pos, setPos] = useState(0)

    setVH()

    const changePage = (page: number) => {
        if (page >= books.length) {
            setPos(0)
        } else if (page < 0) {
            setPos(books.length - 1)
        } else setPos(page)
    }

    useEffect(() => {
        window.addEventListener('resize', setVH)

        const fetchBooks = async () => {
            const response = await fetch(url + '?type=books', {
                redirect: 'follow',
                // mode: 'no-cors',
            })
            console.log(response)
            const data = await response.json()
            console.log(data)
            setBooks(data.books.reverse())
        }
        fetchBooks()
    }, [])

    if (books.length === 0) return null

    return (
        <div className='App'>
            <iframe
                src={getPreviewURL(books[pos].media)}
                className='book-iframe'
            ></iframe>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    maxWidth: '400px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <button onClick={() => changePage(pos - 1)} className='button'>
                    {'<'}
                </button>

                <p>
                    {books[pos].title +
                        (books[pos].category !== ''
                            ? ' - ' + books[pos].category
                            : '')}{' '}
                    ({books[pos].id})
                </p>

                <a href={baseFormURL + books[pos].id} target='_blank'>
                    Request
                </a>

                <button onClick={() => changePage(pos + 1)} className='button'>
                    {'>'}
                </button>
            </div>
        </div>
    )
}

export default App
